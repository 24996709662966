import mainjson from './main';
export default function GetData() {
        return {
        "apitype":"gameoptions",
        "id":"game_answer_id",
        "subid":"game_answer_question_id",
        "options":["edit","insert","delete"],
        "nameSingle":"option",
        "nameMultiple":"options",
        "order":"game_question_order",
        "orderArrows":true,
        "fields":{
            "field1":{
                "name":"Option Text",
                "field":"game_answer_text",
                "type":"TextInput",
                "required":false,
                "list":true,
            },
            "field2":{
                "name":"Sound (OGG)",
                "field":"game_answer_audio",
                "type":"SoundUpload",
                "required":false,
                "list":true,
            },
            "field3":{
                "name":"Image",
                "field":"game_answer_image",
                "type":"ImageUpload",
                "resize":{
                    width:512,
                    height:512,
                    type:"fit",
                    quality:0.85,
                    bgcolor:"#ffffff"
                },
            },
            "field4":{
                "name":"Correct Option?",
                "field":"game_answer_correct",
                "type":"CheckBox",
                "list":true,
              
            },
        }
    }
    
}